import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { CommonLabels } from '../../models/common.model';
import { commonLabels } from '../../common.labels';
import { ResizeWindowService } from '../../services/resize-window.service';

@Component({
  selector: 'search-input',
  templateUrl: './search-input.component.html',
  styleUrls: ['./search-input.component.scss']
})
export class SearchInputComponent implements OnChanges {
  @Input() searchValue?: string;
  @Input() minCharacterToFilter: number;
  @Output() emitInputFilter = new EventEmitter();
  @Output() emitClearFilter = new EventEmitter();
  className = 'search-input';
  theme = 'marketplace-default';
  labels: CommonLabels = commonLabels;
  searchInput: string;
  classFilter = '';
  placeholder: string;

  constructor(private resizeWindowService: ResizeWindowService) {
    this.placeholder = 'Fill and press Enter to search';
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.searchValue.previousValue !== changes.searchValue.currentValue) {
      this.searchInput = changes.searchValue.currentValue;
      if (this.searchInput.length > 0) {
        this.classFilter = 'focus';
      }
    }
  }

  /* Buscando según se escribe
  onInputFilter($event) {
    console.log(this.searchInput, $event);
    if (
      $event.code === 'Backspace' &&
      $event.target.value.length > 0 &&
      $event.target.value.length < this.minCharacterToFilter
    ) {
      return;
    } else {
      if ($event.target.value.length >= this.minCharacterToFilter) {
        this.resizeWindowService.waitForFinalEvent(
          () => {
            this.emitInputFilter.emit({
              event: $event,
              value: this.searchInput
            });
          },
          500,
          'search filter'
        );
      } else if ($event.target.value.length === 0) {
        this.onClearFilter({
          clear: true,
          value: this.searchInput
        });
      } else {
        return;
      }
    }
  }*/

  //Buscando con enter
  onInputFilter($event) {
    //console.log(this.searchInput, $event);
    if (
      $event.code === 'Backspace' &&
      $event.target.value.length > 0 &&
      $event.target.value.length < this.minCharacterToFilter
    ) {
      return;
    } else {
      if ($event.target.value.length >= this.minCharacterToFilter) {
        if ($event.code === 'Enter' || $event.key === 'Enter') {
          this.emitInputFilter.emit({
            event: $event,
            value: this.searchInput
          });
        }
      } else if ($event.target.value.length === 0) {
        this.emitClearFilter.emit({
          clear: true,
          value: this.searchInput
        });
      } else {
        return;
      }
    }
  }

  addClassFocus() {
    this.classFilter = 'focus';
  }

  removeClassFocus() {
    this.classFilter = '';
  }

  onClearFilter($event) {
    // console.log($event);
    this.emitClearFilter.emit({
      clear: true,
      value: this.searchInput
    });
  }
}
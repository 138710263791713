import { Component, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatSidenav } from '@angular/material/sidenav';
import { Router } from '@angular/router';
import { commonLabels } from 'app/common/common.labels';
import { CategoryModel, CommonLabels } from 'app/common/models/common.model';
import { AuthService } from '../../../../common/services/auth.service';
import { StorageService } from '../../../../common/services/storage.service';
import { CategoriesService } from '../../../../common/services/categories.service';
import { CategoriesStoreService } from '../../../../common/services/category-observable.service';
import { ResizeWindowService } from '../../../../common/services/resize-window.service';
import { KeycloakService } from 'keycloak-angular';
import { ModalMessageComponent } from '../../../../common/components/modal-message/modal-message.component';
import { MatDialog } from '@angular/material/dialog';
import { AutoUnsubscribe } from 'ngx-auto-unsubscribe';
import { SnackMessageService } from '../../../../common/services/snackMessage.service';

@AutoUnsubscribe()
@Component({
  selector: 'app-main-layout-public',
  templateUrl: 'main-layout-public.component.html',
  styleUrls: ['main-layout-public.component.scss']
})
export class MainLayoutPublicComponent implements OnInit, OnDestroy {
  @Input() isDesktop: boolean;
  @Input() width: number;
  @Input() theme: string;
  @Input() showMenu? = true;
  @Input() showMenuEcosystem? = false;
  @ViewChild('sidenav') sidenav: MatSidenav;

  className = 'main-layout';
  labels: CommonLabels = commonLabels;
  isLoading = false;
  dataCategory: CategoryModel[] = [];

  constructor(
    private router: Router,
    public matDialog: MatDialog,
    private authService: AuthService,
    private storageService: StorageService,
    private readonly resizeWindowService: ResizeWindowService,
    private categoriesService: CategoriesService,
    private categoriesStoreService: CategoriesStoreService,
    private snackMessageService: SnackMessageService,
    private readonly keycloak: KeycloakService
  ) {}

  ngOnInit(): void {
    this.isLoading = true;
    this.showMenu = this.width > 600;
    this.categoriesService.getAllCategoriesP().subscribe({
      next: (categories: any) => {
        this.dataCategory = categories;
        this.categoriesStoreService.setCategoryListPublic(this.dataCategory);
        this.isLoading = false;
      },
      error: (error) => {
        this.isLoading = false;
        if (error.error.response !== undefined) {
          this.snackMessageService.readError(error);
        } else {
          this.snackMessageService.sendMessage(error.error);
        }
      }
    });
  }

  closeMenu(): void {
    this.categoriesStoreService.setStateMenu('close');
    this.showMenu = false;
    this.showMenuEcosystem = false;
    this.sidenav.close();
  }

  openMenu(): void {
    this.categoriesStoreService.setStateMenu('open');
    this.showMenu = true;
    this.showMenuEcosystem = false;
    this.sidenav.open();
  }

  closeMenuEcosystem(): void {
    this.showMenuEcosystem = false;
    this.sidenav.close();
  }

  openMenuEcosystem(): void {
    this.showMenuEcosystem = true;
    this.sidenav.close();
  }

  openWindow(url, target) {
    this.showMenuEcosystem = !this.showMenuEcosystem;
    window.open(url, target);
  }

  openModalContact($event) {
    const dialogRef = this.matDialog.open(ModalMessageComponent, {
      height: 'auto',
      width: this.width >= 760 ? '700px' : '100vw',
      maxHeight: '100vh',
      maxWidth: '100vw',
      panelClass: 'background-white',
      backdropClass: 'backdropBackground',
      autoFocus: false,
      data: {
        event: $event,
        type: 'contact-form-public',
        imgHeader: 'assets/images/logos/market_avatar.svg',
        title: 'Contact us',
        linkHeader: ' login.',
        textHeader1: 'You need to be a Barbara user to access the Marketplace, please ',
        textHeader2:
          'If you are not a user yet and you are interested in joining the Barbara platform, please fill out the form below and we will get back to you as soon as possible',
        terms: '',
        buttons: [
          {
            matDialogClose: true,
            color: 'purple-inverted',
            icon: 'cancel',
            text: this.labels.close,
            action: 'close'
          }
        ]
      }
    });
    dialogRef.afterClosed().subscribe((result) => {
      console.log('The dialog was closed', result);
      if (result.action !== 'close') {
        console.log('ver qué se hace', result);
      }
    });
  }

  public login() {
    this.storageService.removelocalStorageData();
    this.authService.setUserLoggedIn(false);
    this.keycloak.login();
  }

  identify(index, item) {
    return index;
  }

  ngOnDestroy(): void {}
}
import { inject, Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { CategoryModel } from 'app/common/models/common.model';
import { UtilsService } from './utils.service';

@Injectable({
  providedIn: 'root'
})
export class CategoriesStoreService {
  categoryList = new BehaviorSubject([{} as CategoryModel]);
  categoryListPublic = new BehaviorSubject([{} as CategoryModel]);
  stateMenu = new BehaviorSubject('');
  featuredCategoryId = new BehaviorSubject('');
  featuredCategory = new BehaviorSubject({} as CategoryModel);
  purchasedCategoryId = new BehaviorSubject('');
  purchasedCategory = new BehaviorSubject({} as CategoryModel);
  mostPurchasedCategoryId = new BehaviorSubject('');
  mostPurchasedCategory = new BehaviorSubject({} as CategoryModel);
  specialCategoryId = new BehaviorSubject('');
  specialCategory = new BehaviorSubject({} as CategoryModel);
  suggestedCategoryId = new BehaviorSubject('');
  suggestedCategory = new BehaviorSubject({} as CategoryModel);
  utilsService = inject(UtilsService);

  setCategoryList(categoriesList: CategoryModel[]) {
    let _categoriesList = categoriesList;
    //Dejamos sólo las aplicaciones que estén habilitadas y sean privadas
    _categoriesList.map((category) => {
      category.applications = this.utilsService.filterCategoryApplicationsPrivateAndEnabled(
        category.applications
      );
      return category;
    });
    _categoriesList.forEach((category, index) => {
      //Actualizamos categorías especiales
      switch (category.type) {
        case 1:
          this.setFeaturedCategoryId(category._id);
          this.setFeaturedCategory(category);
          break;
        case 2:
          this.setPurchasedCategoryId(category._id);
          this.setPurchasedCategory(category);
          break;
        case 3:
          this.setMostPurchasedCategoryId(category._id);
          this.setMostPurchasedCategory(category);
          break;
        case 4:
          this.setSpecialCategoryId(category._id);
          this.setSpecialCategory(category);
          break;
        case 5:
          this.setSuggestedCategoryId(category._id);
          this.setSuggestedCategory(category);
          break;
        default:
          //standard
          break;
      }
    });

    //console.log(_categoriesList);
    this.categoryList.next(_categoriesList);
  }

  getCategoryList(): BehaviorSubject<CategoryModel[]> {
    //console.log(this.categoryList);
    return this.categoryList;
  }

  getCategoryById(id: string): CategoryModel {
    //console.log(id);
    return this.categoryList.value.find((cat) => cat['_id'] === id);
  }

  setStateMenu(state) {
    this.stateMenu.next(state);
  }

  getStateMenu(): any {
    return this.stateMenu.asObservable();
  }

  /* Featured Category */
  setFeaturedCategoryId = (id: string): void => {
    this.featuredCategoryId.next(id);
  };

  getFeaturedCategoryId(): BehaviorSubject<string> {
    return this.featuredCategoryId;
  }

  setFeaturedCategory(category: CategoryModel) {
    this.featuredCategory.next(category);
  }

  getFeaturedCategory(): BehaviorSubject<CategoryModel> {
    return this.featuredCategory;
  }

  /* Purchased Category */
  setPurchasedCategoryId = (id: string): void => {
    this.purchasedCategoryId.next(id);
  };

  getPurchasedCategoryId(): BehaviorSubject<string> {
    return this.purchasedCategoryId;
  }

  setPurchasedCategory(category: CategoryModel) {
    this.purchasedCategory.next(category);
  }

  getPurchasedCategory(): BehaviorSubject<CategoryModel> {
    return this.purchasedCategory;
  }

  /* Most Used Category */
  setMostPurchasedCategoryId = (id: string): void => {
    this.mostPurchasedCategoryId.next(id);
  };

  getMostPurchasedCategoryId(): BehaviorSubject<string> {
    return this.mostPurchasedCategoryId;
  }

  setMostPurchasedCategory(category: CategoryModel) {
    this.mostPurchasedCategory.next(category);
  }

  getMostPurchasedCategory(): BehaviorSubject<CategoryModel> {
    return this.mostPurchasedCategory;
  }

  /* Special Category */
  setSpecialCategoryId = (id: string): void => {
    this.specialCategoryId.next(id);
  };

  getSpecialCategoryId(): BehaviorSubject<string> {
    return this.specialCategoryId;
  }

  setSpecialCategory(category: CategoryModel) {
    this.specialCategory.next(category);
  }

  getSpecialCategory(): BehaviorSubject<CategoryModel> {
    return this.specialCategory;
  }

  /* Suggested Apps Category */
  setSuggestedCategoryId = (id: string): void => {
    this.suggestedCategoryId.next(id);
  };

  getSuggestedCategoryId(): BehaviorSubject<string> {
    return this.suggestedCategoryId;
  }

  setSuggestedCategory(category: CategoryModel) {
    this.suggestedCategory.next(category);
  }

  getSuggestedCategory(): BehaviorSubject<CategoryModel> {
    return this.suggestedCategory;
  }

  setCategoryListPublic(categoriesList: CategoryModel[]) {
    this.categoryListPublic.next(categoriesList);
  }

  getCategoryListPublic(): BehaviorSubject<CategoryModel[]> {
    return this.categoryListPublic;
  }
}
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { Permissions } from '../models/common.model';
import { StorageService } from './storage.service';
import { BackEndCommunicationService } from './beComm.service';
import { Router } from '@angular/router';
import { KeycloakService } from 'keycloak-angular';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  private token: string;
  private isBBRAdmin: boolean;
  private avatarUri: string;
  private email: string;
  private name: string;
  private permissions: Permissions;
  private userName$: BehaviorSubject<string>;
  private userAvatar$: BehaviorSubject<string>;
  private isAdmin$: BehaviorSubject<boolean>;
  private userLoggedIn = new Subject<boolean>();
  private status2fa: boolean;

  constructor(
    private storageService: StorageService,
    private backEndCommunicationService: BackEndCommunicationService,
    private readonly keycloak: KeycloakService,
    private readonly router: Router
  ) {
    this.token = '';
    this.isBBRAdmin = true;
    this.avatarUri = '';
    this.email = '';
    this.name = '';
    this.permissions = { deplyMgmt: true, deviceMgmt: true, userMgmt: true };
    this.token = this.storageService.retrieveAuthToken();
    this.isBBRAdmin = this.storageService.retrieveIsAdmin();
    this.avatarUri = this.storageService.retrieveAvatarUri();
    this.email = this.storageService.retrieveEmail();
    this.name = this.storageService.retrieveName();
    this.permissions.deplyMgmt = this.storageService.retrieveDeplyPermission();
    this.permissions.deviceMgmt = this.storageService.retrieveDevicePermission();
    this.permissions.userMgmt = this.storageService.retrieveUserPermission();
    this.userName$ = new BehaviorSubject<string>(this.name);
    this.userAvatar$ = new BehaviorSubject<string>(this.avatarUri);
    this.isAdmin$ = new BehaviorSubject<boolean>(this.isBBRAdmin);
    this.userLoggedIn.next(false);
    this.status2fa = false;
  }

  public getUserAvatar = (): Observable<string> => {
    return this.userAvatar$.asObservable();
  };

  public setUserAvatar = (userAvatar: string): void => {
    this.setAvatarUri(userAvatar);
    this.userAvatar$.next(userAvatar);
  };

  public getUserName = (): Observable<string> => {
    return this.userName$.asObservable();
  };

  public setUserName = (userName: string): void => {
    this.setName(userName);
    this.userName$.next(userName);
  };

  public isLoggedIn = (): boolean => {
    if (!this.token) {
      return false;
    } else {
      return this.token !== '';
    }
  };

  public setUserLoggedIn(userLoggedIn: boolean): void {
    this.userLoggedIn.next(userLoggedIn);
  }

  public getUserLoggedIn(): Observable<boolean> {
    return this.userLoggedIn.asObservable();
  }

  public isAdmin = (): Observable<boolean> => {
    return this.isAdmin$.asObservable();
  };

  public getToken = (): string => {
    return this.token;
  };

  public setToken = (token: string): void => {
    this.token = token;
  };

  public setIsAdmin = (value: boolean): void => {
    this.isBBRAdmin = value;
    this.isAdmin$.next(value);
    this.storageService.storeIsAdmin(this.isBBRAdmin.valueOf());
  };

  public setAvatarUri = (value: string): void => {
    this.avatarUri = value;
    this.storageService.storeAvatarUri(this.avatarUri);
  };

  public setEmail = (value: string): void => {
    this.email = value;
    this.storageService.storeEmail(this.email);
  };

  public setName = (value: string): void => {
    this.name = value;
    this.storageService.storeName(this.name);
  };

  public setDeplyPermission = (value: boolean): void => {
    this.permissions.deplyMgmt = true;
    this.storageService.storeDeplyPermission(this.permissions.deplyMgmt.valueOf());
  };

  public setDevicePermission = (value: boolean): void => {
    this.permissions.deviceMgmt = true;
    this.storageService.storeDevicePermission(this.permissions.deviceMgmt.valueOf());
  };

  public setUserPermission = (value: boolean): void => {
    this.permissions.userMgmt = true;
    this.storageService.storeUserPermission(this.permissions.userMgmt.valueOf());
  };

  public getAvatarUri = (): string => {
    return this.avatarUri;
  };

  public getEmail = (): string => {
    return this.email;
  };

  public getName = (): string => {
    return this.name;
  };

  public hasDeplyPermission = (): boolean => {
    return true;
  };

  public hasDevicePermission = (): boolean => {
    return true;
  };

  public hasUserPermission = (): boolean => {
    return true;
  };

  public getStatus2fa = (): boolean => {
    return this.status2fa;
  };

  public setStatus2fa = (status2fa: boolean): void => {
    this.status2fa = status2fa;
  };

  public removeUserData = (): void => {
    this.token = '';
    this.isBBRAdmin = false;
    this.avatarUri = '';
    this.email = '';
    this.name = '';
    this.permissions = { deplyMgmt: true, deviceMgmt: true, userMgmt: true };
  };

  sendFormContactUs(data: any): any {
    this.backEndCommunicationService.postResourceObservable('/contact', data).subscribe(
      (response) => {
        console.log(response);
      },
      (error) => {
        console.log(error);
      }
    );
  }

  closeSession(): void {
    this.logout();
    this.storageService.removelocalStorageData();
    this.setUserLoggedIn(false);
    this.router.navigate(['/public/_home']);
  }

  public logout() {
    this.keycloak.logout();
    this.router.navigate(['/public/_home']);
  }
}
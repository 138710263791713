<div class="{{ theme }} {{ className }}">
  <breadcrumbs
    [categoryPurchasedId]="categoryPurchasedId"
    [categorySpecialId]="categorySpecialId"></breadcrumbs>
  <ng-container *ngIf="dataApplication">
    <div class="{{ theme }} {{ className }}__header">
      <div class="{{ className }}__header-logo">
        <img
          [src]="dataApplication.icon"
          alt="app icon"
          class="{{ theme }} {{ className }}__header-logo-app" />

        @if (showMostUsed) {
          <div class="{{ theme }} {{ className }}__header-logo-img-shape most-purchased">
            <mat-icon
              class="{{ theme }} {{ className }}__header-logo-img-shape-icon most-purchased"
              matTooltip="{{ 'Best Seller' }}"
              matTooltipClass="{{ theme }} custom-tooltip"
              matTooltipPosition="above">
              {{ categoryMostPurchased.icon }}
            </mat-icon>
          </div>
        }
        @if (dataApplication.purchased) {
          <div class="{{ theme }} {{ className }}__header-logo-img-shape purchased">
            <mat-icon
              class="{{ theme }} {{ className }}__header-logo-img-shape-icon purchased"
              matTooltip="{{ 'In library' }}"
              matTooltipClass="{{ theme }} custom-tooltip"
              matTooltipPosition="above">
              check_small
            </mat-icon>
          </div>
        }
      </div>
      <div class="{{ theme }} {{ className }}__header-col title">
        <h2
          class="{{ theme }} {{ className }}__header-name-app"
          id="{{ utilsService.removeSpacesAndInsertScript('title-' + dataApplication.name) }}"
          matTooltip="{{ title }}"
          matTooltipClass="{{ theme }} custom-tooltip"
          matTooltipPosition="above">
          {{ dataApplication.name }}
        </h2>
        <div
          [innerHtml]="dataApplication.shortDescription | safe: 'html'"
          class="{{ theme }} {{ className }}__header-text-app"
          id="{{ className }}__header-text-app}}"
          matTooltip="{{ shortDescription }}"
          matTooltipClass="{{ theme }} custom-tooltip"
          matTooltipPosition="above"></div>
        <div class="{{ theme }} {{ className }}__categories">
          <ul class="{{ theme }} {{ className }}__category-tags-list">
            @for (category of dataApplication.categories; track $index) {
              @if (category._id === categorySpecialId || category.type === 4) {
                <li class="{{ theme }} {{ className }}__category-tags-list-item order">
                  <div class="{{ theme }} {{ className }}__category-tags-list-item-shape">
                    <img alt="barbara icon" src="assets/images/icons/b_white.svg" />
                  </div>
                </li>
              } @else {
                @if (category.type === 0) {
                  <li class="{{ theme }} {{ className }}__category-tags-list-item">
                    <span class="{{ theme }} {{ className }}__category-tags-list-item-text">
                      {{ category.name }}
                    </span>
                  </li>
                }
              }
            }
          </ul>
        </div>
      </div>
      <div class="{{ theme }} {{ className }}__header-col button">
        <button
          (click)="contact(dataApplication._id, typeModal)"
          *ngIf="showContactButton"
          [disabled]="disabledButton"
          class="big"
          color="accent"
          id="contact-button"
          mat-flat-button>
          <mat-icon class="material-icons-outline">contact_support</mat-icon>
          {{ labels.requestInfo }}
        </button>
        <button
          (click)="addToLibrary(dataApplication._id, typeModal)"
          *ngIf="showAddToLibraryButton"
          [disabled]="disabledButton"
          class="big"
          color="accent"
          id="add-to-library-button"
          mat-flat-button>
          <mat-icon class="material-icons-outline">book</mat-icon>
          {{ labels.addToLibrary }}
        </button>
        <button
          *ngIf="showAlreadyInLibraryButton"
          [disabled]="disabledButton"
          class="big"
          color="accent"
          id="already-in-library-button"
          mat-flat-button>
          <mat-icon class="material-icons-outline">book</mat-icon>
          {{ labels.alredyInLibrary }}
        </button>
      </div>
    </div>
    <div class="{{ theme }} {{ className }}__subheader">
      <ul class="{{ theme }} {{ className }}__subheader-list">
        @if (
          dataApplication.versions?.length > 0 &&
          dataApplication.versions[lastVersion].name &&
          dataApplication.type === 0
        ) {
          <li class="{{ theme }} {{ className }}__subheader-list-item">
            <span class="{{ theme }} {{ className }}__subheader-list-item-label">
              {{ labels.version }}
            </span>
            <span class="{{ theme }} {{ className }}__subheader-list-item-value" id="version-name">
              {{ dataApplication.versions[lastVersion].name }}
            </span>
          </li>
        }
        @if (dataApplication.developer?.length > 0 && dataApplication.developer) {
          <li class="{{ theme }} {{ className }}__subheader-list-item">
            <span
              class="{{ theme }} {{ className }}__subheader-list-item-label"
              id="version-publisher">
              {{ labels.publisher }}
            </span>
            <span
              class="{{ theme }} {{ className }}__subheader-list-item-value"
              id="version-developer">
              {{ dataApplication.developer }}
            </span>
          </li>
        }
        @if (
          dataApplication.versions?.length > 0 && dataApplication.versions[lastVersion].created
        ) {
          <li class="{{ theme }} {{ className }}__subheader-list-item">
            <span class="{{ theme }} {{ className }}__subheader-list-item-label" id="version-date">
              {{ labels.date }}
            </span>
            <span
              class="{{ theme }} {{ className }}__subheader-list-item-value"
              id="version-created">
              {{ dataApplication.versions[lastVersion].created | date: 'MM/dd/YYYY' }}
            </span>
          </li>
        }
        @if (
          dataApplication.versions?.length > 0 &&
          dataApplication.versions[lastVersion].size &&
          dataApplication.type === 0
        ) {
          <li class="{{ theme }} {{ className }}__subheader-list-item">
            <span class="{{ theme }} {{ className }}__subheader-list-item-label">{{
              labels.size
            }}</span>
            <span class="{{ theme }} {{ className }}__subheader-list-item-value" id="version-size">
              {{ dataApplication.versions[lastVersion].size | convertBytes }}
            </span>
          </li>
        }
        @if (
          dataApplication.versions?.length > 0 &&
          dataApplication.versions[lastVersion].architectures.length > 0 &&
          dataApplication.type === 0
        ) {
          <li class="{{ theme }} {{ className }}__subheader-list-item">
            <span class="{{ theme }} {{ className }}__subheader-list-item-label">
              {{ labels.architectures }}
            </span>
            @for (
              architecture of dataApplication.versions[lastVersion].architectures;
              track $index
            ) {
              <span
                [id]="utilsService.removeSpacesAndInsertScript(architecture)"
                class="{{ theme }} {{ className }}__subheader-list-item-value">
                {{ architecture | architecture }}{{ $last ? '' : ',' }}&nbsp;
              </span>
            }
          </li>
        }
      </ul>
    </div>
    @if (dataApplication.pictures.length > 1) {
      <div class="{{ theme }} {{ className }}__wrapper">
        <ul class="{{ theme }} {{ className }}__list-image">
          @for (carouselImage of dataApplication.pictures; track $index) {
            <li *ngIf="$index !== 0" class="{{ theme }} {{ className }}__list-image-item">
              <img
                [src]="carouselImage"
                alt="app img"
                class="{{ theme }} {{ className }}__list-image-item-image" />
            </li>
          }
        </ul>
      </div>
    }

    <div class="{{ theme }} {{ className }}__tabs">
      <mat-tab-group
        #tabList
        (selectedTabChange)="selectedTabChange($event)"
        animationDuration="0ms"
        class="no-padding">
        dynamicHeight>
        <mat-tab [label]="labels.description" id="app-description">
          <div class="{{ theme }} {{ className }}__description">
            <div
              [innerHtml]="
                dataApplication.longDescription !== ''
                  ? dataApplication.longDescription
                  : (labels.noDescriptionFound | safe: 'html')
              "
              class="{{ theme }} {{ className }}__description-text"></div>
          </div>
        </mat-tab>
        <mat-tab
          *ngIf="readme !== '' && dataApplication.type === 0"
          [label]="labels.technicalNotes"
          id="app-technical-notes">
          <div class="{{ theme }} {{ className }}__readme">
            <app-readme-md [readme]="readme"></app-readme-md>
          </div>
        </mat-tab>
      </mat-tab-group>
    </div>
  </ng-container>

  <div
    *ngIf="categorySuggested !== undefined && arrayApplicationFeatured.length > 0"
    class="{{ theme }} {{ className }}__category">
    <div class="{{ theme }} {{ className }}__category-wrapper">
      <h3 class="{{ theme }} {{ className }}__category-title">
        <span class="{{ theme }} {{ className }}__category-title-text">
          {{ 'Suggested Apps' }}
        </span>
      </h3>
    </div>
    <ul
      *ngIf="arrayApplicationFeatured.length > 0"
      class="{{ theme }} {{ className }}__category-list">
      @for (app of arrayApplicationFeatured; track $index) {
        @if (app.enabled) {
          @if (
            width >= 3800 ? $index <= 4 : width < 3800 && width >= 1800 ? $index <= 3 : $index <= 2
          ) {
            <li class="{{ theme }} {{ className }}__category-list-item">
              <app-card-horizontal
                (moreButtonEvent)="onClickMoreButton(app._id)"
                [_data]="app"
                [categoryFeaturedId]="categoryFeaturedId"
                [categoryFeatured]="categoryFeatured"
                [categoryMostPurchasedId]="categoryMostPurchasedId"
                [categoryMostPurchased]="categoryMostPurchased"
                [categoryPurchasedId]="categoryPurchasedId"
                [categoryPurchased]="categoryPurchased"
                [categorySpecialId]="categorySpecialId"
                [categorySpecial]="categorySpecial"
                [categorySuggestedId]="categorySuggestedId"
                [categorySuggested]="categorySuggested"
                [isDesktop]="isDesktop"
                [theme]="theme"
                [width]="width"></app-card-horizontal>
            </li>
          }
        }
      }
    </ul>
  </div>
</div>